<script lang="ts" setup>
import { useNavState } from '#core/utils/nav'
import { createReusableTemplate, useMutationObserver, useWindowSize } from '@vueuse/core'

interface Options {
  title?: string
  icon?: string
  cta?: string
  contact?: boolean
  containerClass?: false | string
}

const props = withDefaults(defineProps<Options>(), { contact: true, containerClass: 'max-w-[1200px]' })

const options = computed<Options>(() => {
  return { ...props, ...useRoute().meta }
})

useHead({
  title: options.value.title,
})

const [DefineFooter, ReuseFooter] = createReusableTemplate()
const slots = useSlots()

const { slideoverMenu, expanded } = useNavState()
const hasSidebarSlot = ref(false)
// make sure menu is closed between route changes
const route = useRoute()
watch(route, () => {
  slideoverMenu.value = null
}, {
  immediate: true,
})
const { width } = useWindowSize()
const shouldExpandMenuFromWidth = computed(() => width.value > 1460)
hasSidebarSlot.value = !!slots.sidebar
watch([shouldExpandMenuFromWidth, hasSidebarSlot], () => {
  if (hasSidebarSlot.value) {
    expanded.value = false
    return
  }
  expanded.value = shouldExpandMenuFromWidth.value
}, {
  immediate: true,
})

const sidebarContainer = useTemplateRef('sidebarContainer')
// we can't watch slots so we need to watch the DOM
// @ts-expect-error upstream type issue
useMutationObserver(sidebarContainer, () => {
  hasSidebarSlot.value = sidebarContainer.value?.children.length
}, {
  childList: true,
})
</script>

<template>
  <DefineFooter>
    <slot name="footer" />
  </DefineFooter>
  <div data-layout="core-dashboard">
    <div
      class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
    >
      <div class="flex min-h-screen relative">
        <CoreMenu />
        <main class="flex flex-col flex-grow" data-inertable>
          <!-- page header -->
          <div
            class="h-[80px] px-5 flex relative justify-between items-center border-b border-forgd-bgd-600"
          >
            <slot name="header">
              <div class="flex gap-2 items-center">
                <img v-if="options.icon && options.icon.endsWith('.svg')" :src="options.icon" class="h-[32px]">
                <div class="text-sm font-semibold flex-shrink-0">
                  {{ options.title }}
                </div>
              </div>
            </slot>

            <CoreSearchButton class="absolute left-1/2 transform -translate-x-1/2" />

            <div class="flex-grow-0 flex items-center gap-3">
              <CoreHeaderProfile />
            </div>
          </div>

          <div class="min-h-[calc(100vh-80px)] flex w-full">
            <div ref="sidebarContainer">
              <slot v-if="$slots.sidebar" name="sidebar" />
            </div>
            <div class="flex-grow w-full">
              <div class="mx-auto flex-grow space-y-10" :class="containerClass ? [containerClass, 'p-5'] : []">
                <slot />
              </div>
              <ReuseFooter v-if="$slots.sidebar" />
            </div>
          </div>
          <ReuseFooter v-if="!$slots.sidebar" />
          <CoreFooter />
        </main>
      </div>
    </div>
    <CoreMenuSlideover :style="{ zIndex: 10 }">
      <template #default="defaultSlotAttrs">
        <slot name="slideover" v-bind="defaultSlotAttrs" />
      </template>
    </CoreMenuSlideover>
    <UNotifications />
    <USlideovers />
  </div>
</template>
