<script setup lang="ts">
import { useNavState } from '#core/utils/nav'
import { topLevelMenu } from '#imports'

const { expanded } = useNavState()

const dashboardLink = useRuntimeConfig().public.featureFlags.dashboard
</script>

<template>
  <div
    data-el="AppMenuExpandable"
    class="flex-shrink-0 border-r bg-forgd-primary-900 font-display max-h-screen sticky top-0"
    :style="{ zIndex: 100 }"
  >
    <nav
      class="flex flex-col h-full justify-between divide-y divide-gray-700 max-h-screen"
      :class="expanded ? 'w-[260px]' : 'w-[52px]'"
      aria-label="Site Navigation"
    >
      <div class="min-h-[80px] flex items-center justify-center w-full">
        <NuxtLink :to="dashboardLink">
          <CoreLogo :collapsed="!expanded" />
        </NuxtLink>
      </div>
      <div class="py-4 flex-grow px-2 max-h-full overflow-y-auto min-height-[93px] dark-scrollbar">
        <CoreMenuNavigationList
          :items="topLevelMenu[0]"
        />
      </div>
      <div class="py-4 px-2 opacity-70">
        <CoreMenuNavigationList
          :items="topLevelMenu[1]"
        />
      </div>
    </nav>
  </div>
</template>
